<template>
  <div>
    <nav class="px-4 lg:px-6 py-4">
      <div class="flex justify-between items-center mx-auto">
        <!-- Logo/Brand -->
        <a href="\#" class="font-proxima font-bold flex items-center">
          <img src="@/assets/logo.png" alt="ścieżki" class="ml-4 sm:ml-16 h-8 w-auto object-contain" />
        </a>

        <!-- Navigation Links -->
        <div class="hidden sm:flex items-center space-x-8">
          <a href="\#about" class="font-proxima font-semibold" v-html="headerCont.about"></a>
          <a href="\#events" class="font-proxima font-semibold" v-html="headerCont.events"></a>
          <a href="\#reviews" class="font-proxima font-semibold" v-html="headerCont.reviews"></a>
          <JoinButton small />
        </div>
      </div>
    </nav>
    <!-- Separator with angled path -->
    <TailSeparator />
  </div>
</template>

<script>
import JoinButton from './JoinButton.vue'
import TailSeparator from './TailSeparator.vue'
import { loadContent } from '../utils/contentLoader';

export default {
  name: 'SectionHeader',
  components: {
    JoinButton,
    TailSeparator
  },
  data() {
    return {
      headerCont: {
        "about": "###",
        "reviews": "###"
      }
    }
  },
  async created() {
    const result = await loadContent('/content/header.json', 'Error loading header content');
    if (result) {
      this.headerCont = result;
    }
  }
}
</script>

<style scoped></style>
